import React, { useState } from 'react';
import StructuredText from 'src/components/base/structuredText';
import FormControl from 'src/components/base/formControl';
import FileInput from 'src/components/base/fileInput';
import { STATES, REFERRAL_REASON, submitForm } from 'src/util';
import Dots from 'react-activity/dist/Dots';
import * as classes from './blockQualifiedReferralForm.module.scss';

const BlockQualifiedReferralForm = ({
  patientHeader,
  preformContent,
  patientPostformContent,
  referrerHeader,
  uploadDirections,
  buttonText,
  onComplete,
}) => {
  // Patient
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [birthdate, setBirthdate] = useState();
  const [state, setState] = useState();
  const [treatmentType, setTreatmentType] = useState();
  const [treatmentInfo, setTreatmentInfo] = useState(); // includes diagnosis, evaluation, and treatment info

  // Patient Files
  const [files, setFiles] = useState([]);

  // Referrer
  const [referrerFirstName, setReferrerFirstName] = useState();
  const [referrerLastName, setReferrerLastName] = useState();
  const [referrerOrganization, setReferrerOrganization] = useState();
  const [providerNpi, setProviderNpi] = useState();

  // Form
  const [submitting, setSubmitting] = useState(false);

  return (
    <div>
      <form
        id="qualifiedReferral"
        action={process.env.GATSBY_QUALIFIED_REFERRAL_HANDLER}
        onSubmit={(evt) => {
          setSubmitting(true);
          submitForm(evt, files, onComplete, () => setSubmitting(false));
        }}
      >
        <StructuredText data={preformContent} />

        <input type="hidden" name="id" value="onboarding" />
        <input type="hidden" name="requestType" value="Qualified Referral" />
        <input type="hidden" name="boulderCarePgNpi" value="1649838590" />

        <h2>{patientHeader}</h2>
        <FormControl
          name="firstName"
          label="First name"
          value={firstName}
          setValue={setFirstName}
          required
        />
        <FormControl
          name="lastName"
          label="Last name"
          value={lastName}
          setValue={setLastName}
          required
        />
        <FormControl
          name="phoneNumber"
          required
          type="tel"
          label="Phone number"
          value={phone}
          setValue={setPhone}
        />
        <FormControl
          name="birthdate"
          type="date"
          label="Birthdate"
          value={birthdate}
          setValue={setBirthdate}
        />
        <FormControl
          name="state"
          label="Primary state of residence"
          options={STATES}
          valueFn={(s) => s.name}
          labelFn={(s) => s.name}
          value={state}
          setValue={setState}
        />
        <FormControl
          name="treatmentType"
          label="Reason for referral"
          options={REFERRAL_REASON}
          value={treatmentType}
          setValue={setTreatmentType}
        />
        <FormControl
          name="treatmentInfo"
          label="Description of the diagnosis, evaluation, and treatment provided to the patient."
          value={treatmentInfo}
          setValue={setTreatmentInfo}
          multi
        />
        <h3>{uploadDirections}</h3>
        <FileInput files={files} setFiles={setFiles} hidePhotoOption={true} />
        <StructuredText data={patientPostformContent} />

        <h2>{referrerHeader}</h2>
        <FormControl
          name="referrerFirstName"
          label="First name"
          value={referrerFirstName}
          setValue={setReferrerFirstName}
          required
        />
        <FormControl
          name="referrerLastName"
          label="Last name"
          value={referrerLastName}
          setValue={setReferrerLastName}
          required
        />
        <FormControl
          name="npi"
          label="Provider NPI"
          value={providerNpi}
          setValue={setProviderNpi}
          required
        />
        <FormControl
          name="referrerPracticeName"
          label="Practice/organization name"
          value={referrerOrganization}
          setValue={setReferrerOrganization}
          required
        />

        <div className={classes.cta}>
          <button type="submit" className="button">
            {submitting ? <Dots color="white" /> : <>{buttonText}</>}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlockQualifiedReferralForm;
